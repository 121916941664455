<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<form-popups
			v-if="currentAlert === 'form'"
			@done="nextAlert" />
		<media-popups
			v-if="currentAlert === 'media'"
			@done="nextAlert" />
		<intro-popup
			v-if="currentAlert === 'intro'"
			@done="nextAlert" />
		<popups
			v-if="currentAlert === 'other'"
			@done="nextAlert" />
		<wallet-alert
			v-if="currentAlert === 'wallet'"
			@done="nextAlert" />
		<new-rank-alert
			v-if="currentAlert === 'ranks'"
			@done="nextAlert" />
		<promotions-alert
			v-if="currentAlert === 'promotions'"
			:promos-info="freeProductsPromotions"
			@done="nextAlert" />
		<order-document-alert
			v-if="currentAlert === 'order_verifications'"
			@done="nextAlert" />
		<v-club-popup
			v-if="currentAlert === 'v_club'"
			@done="nextAlert" />
		<banners :uid="$route.params.distributorId ? Number($route.params.distributorId) : undefined" />
		<!-- Announcements -->
		<div
			v-if="hasAnnouncements"
			class="row">
			<div
				v-for="announcementInfo in announcements"
				:key="announcementInfo.id"
				class="col-12">
				<show-announcement
					:id="Number(announcementInfo.id)"
					:allow-to-hide="!!announcementInfo.attributes.allow_to_hide"
					:theme="announcementInfo.attributes.theme"
					:title="announcementInfo.attributes.title"
					:content="announcementInfo.attributes.content"
					:regards="announcementInfo.attributes.regards"
					:signature="announcementInfo.attributes.signature"
					@hide="hideAnnouncement" />
			</div>
		</div>
		<br v-if="hasAnnouncements">

		<!-- Distributor Data section (welcome, countdown, next purchase date, activated until) -->
		<div class="row">
			<!-- Active multi-payments -->
			<div
				v-if="$user.details().active_multi_payment"
				class="col-12">
				<b-alert
					show
					variant="warning">
					<b-row>
						<b-col class="d-flex align-items-center">
							<span v-html="translate('pending_multi_payment')" />
						</b-col>
						<b-col
							sm="12"
							md="auto"
							class="d-flex flex-column justify-content-center my-1">
							<b-button
								variant="primary"
								@click="$router.push({ name: 'OrdersDetails', params: { order_id: $user.details().active_multi_payment }, query: { option: 'multi_payments' } })">
								{{ translate('go_to_pay') }}
							</b-button>
						</b-col>
					</b-row>
				</b-alert>
			</div>
			<!-- Next Purchase Date -->
			<div
				v-if="!loading && ((showRetirementCountdown && !hasPermanentRank) || isSmallScreen)"
				class="col-12 mb-2">
				<b-alert
					:variant="purchaseInfo.variant"
					class="text-center"
					style="margin: 0px;"
					show>
					<div
						class="h5 my-1"
						v-html="purchaseInfo.text" />
					<div v-if="purchaseInfo.stayActiveProgram !== null">
						<div>
							<span
								class="pointer badge"
								:class="purchaseInfo.stayActiveProgram.is_active ? 'badge-green' : 'badge-secondary'">
								{{ translate('stay_active_program') }}
							</span>
							{{ translate('stay_active_program_text_' + purchaseInfo.stayActiveProgram.is_active) }}
						</div>
						<div>
							<p v-html="translate('current_program_status', { qty: purchaseInfo.stayActiveProgram.applicable_users_qty, of: purchaseInfo.stayActiveProgram.users_qty, link: $router.resolve(stayActivePromoRedirection).href })" />
						</div>
						<div class="align-items-center text-center">
							<img
								v-for="i in purchaseInfo.stayActiveProgram.users_qty"
								:key="i"
								:src="require(`@/assets/images/themes/${themeName}/qualified/${(i <= purchaseInfo.stayActiveProgram.applicable_users_qty) ? 'customer_30_green' : 'customer_30_gray'}.png`)"
								alt="">
						</div>
					</div>
				</b-alert>
			</div>
			<!-- JNS Alert -->
			<div
				v-if="isJns() && $user.details().is_migrated"
				class="col-12">
				<b-alert
					show
					variant="info">
					<b-row>
						<b-col class="d-flex align-items-center justify-content-center">
							<i class="fas fa-lg fa-info-circle pr-2" />
							<span v-html="translate('welcome_message_legacy')" />
						</b-col>
					</b-row>
				</b-alert>
			</div>
			<!-- Auto discount alert -->
			<div
				v-if="!isJns() && !ONLY_MEMBERS_COUNTRIES.includes(requestUserCountry) && requestUserCountry !== ''"
				class="col-12">
				<b-alert
					show
					variant="green">
					<b-row>
						<b-col class="d-flex align-items-center justify-content-center">
							<i class="fas fa-lg fa-tags pr-2" />
							<span v-html="translate('auto_customer_coupons', { min: COUPONS_MIN_PERCENTAGE, max: COUPONS_MAX_PERCENTAGE, link: $router.resolve({ name: 'ReplicatedSite' }).href })" />
						</b-col>
					</b-row>
				</b-alert>
			</div>
			<!-- Welcome user -->
			<div
				v-if="!loading && (showRetirementCountdown || isSmallScreen)"
				class="mb-2"
				:class="{
					'col-md-6 col-sm-12': showRetirementCountdown && hasPermanentRank,
					'col-12': showRetirementCountdown && !hasPermanentRank || isSmallScreen,
					'mt-4': showRetirementCountdown && hasPermanentRank && !isSmallScreen,
				}">
				<div
					class="h4 text-center">
					<span>{{ translate('welcome') }}</span> {{ fullName }}!
					<br v-if="isSmallScreen">
					<span>({{ translate('distributor_id') }}: </span><span class="font-weight-bold">{{ $route.params.distributorId ? $route.params.distributorId : $user.details().id }}</span>)
				</div>
				<b-alert
					v-if="showRetirementCountdown && hasPermanentRank && !isSmallScreen"
					:variant="purchaseInfo.variant"
					class="text-center"
					style="margin: 0px;"
					show>
					<div
						class="h5 my-1"
						v-html="purchaseInfo.text" />
					<div v-if="purchaseInfo.stayActiveProgram !== null">
						<div>
							<span
								class="pointer badge"
								:class="purchaseInfo.stayActiveProgram.is_active ? 'badge-green' : 'badge-secondary'">
								{{ translate('stay_active_program') }}
							</span>
							{{ translate('stay_active_program_text_' + purchaseInfo.stayActiveProgram.is_active) }}
						</div>
						<div>
							<p v-html="translate('current_program_status', { qty: purchaseInfo.stayActiveProgram.applicable_users_qty, of: purchaseInfo.stayActiveProgram.users_qty, link: $router.resolve(stayActivePromoRedirection).href })" />
						</div>
						<div class="align-items-center text-center">
							<img
								v-for="i in purchaseInfo.stayActiveProgram.users_qty"
								:key="i"
								:src="require(`@/assets/images/themes/${themeName}/qualified/${(i <= purchaseInfo.stayActiveProgram.applicable_users_qty) ? 'customer_30_green' : 'customer_30_gray'}.png`)"
								alt="">
						</div>
					</div>
				</b-alert>
			</div>
			<!-- Permanent Rank Badge -->
			<div
				v-if="showRetirementCountdown && hasPermanentRank"
				:class="isSmallScreen ? 'text-center px-0 mb-3' : 'pl-0'"
				class="col-md-6 col-sm-12 px-3 mh-100 d-flex flex-column justify-content-between">
				<div
					v-if="!loading && hasData"
					class="row h-100">
					<div class="col-12">
						<permanent-rank
							:permanent-rank="dashboardData.permanent_rank"
							:permanent-rank-week="dashboardData.permanent_rank_week" />
					</div>
				</div>
				<is-loading
					v-else
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					class="mx-auto" />
			</div>
			<!-- Free Products Promotions Widget-->
			<template v-if="!loading && Object.keys(freeProductsPromotions).length > 0">
				<div
					:class="{
						'text-center px-0 mb-3': isSmallScreen,
						'pl-0': !isSmallScreen,
					}"
					class="col-md-6 col-sm-12 px-3 mh-100 d-flex flex-column justify-content-between">
					<free-products-promotions
						:promotions="freeProductsPromotions" />
				</div>
			</template>
			<div
				v-else
				class="col-md-6 col-sm-12">
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					class="mx-auto" />
			</div>
			<!-- Autoship countdown mixed with retirement countdown or next purchase date -->
			<div
				class="col-12"
				:class="{
					'col-md-6': Object.keys(freeProductsPromotions).length > 0,
				}">
				<div
					v-if="!loading && hasData"
					class="row h-100">
					<!-- Retirement Countdown -->
					<div
						v-if="showRetirementCountdown"
						:class="isSmallScreen ? 'text-center px-0 mb-3' : 'pl-0 pt-2'"
						class="col-12 px-3 mh-100 d-flex flex-column justify-content-between">
						<retirement-countdown
							:retirement-countdown="retirementData" />
					</div>
					<!-- Welcome user -->
					<div
						v-else-if="!isSmallScreen"
						class="mb-2 mt-2 col-12">
						<div class="h4 text-center">
							<span>{{ translate('welcome') }}</span> {{ fullName }}!
							<br v-if="isSmallScreen">
							<span>({{ translate('distributor_id') }}: </span><span class="font-weight-bold">{{ $route.params.distributorId ? $route.params.distributorId : $user.details().id }}</span>)
						</div>
						<b-alert
							:variant="purchaseInfo.variant"
							class="text-center"
							style="margin: 0px;"
							show>
							<div
								class="h5 my-1"
								v-html="purchaseInfo.text" />
							<div v-if="purchaseInfo.stayActiveProgram !== null">
								<div>
									<span
										class="pointer badge"
										:class="purchaseInfo.stayActiveProgram.is_active ? 'badge-green' : 'badge-secondary'">
										{{ translate('stay_active_program') }}
									</span>
									{{ translate('stay_active_program_text_' + purchaseInfo.stayActiveProgram.is_active) }}
								</div>
								<div>
									<p v-html="translate('current_program_status', { qty: purchaseInfo.stayActiveProgram.applicable_users_qty, of: purchaseInfo.stayActiveProgram.users_qty, link: $router.resolve(stayActivePromoRedirection).href })" />
								</div>
								<div class="align-items-center text-center">
									<img
										v-for="i in purchaseInfo.stayActiveProgram.users_qty"
										:key="i"
										:src="require(`@/assets/images/themes/${themeName}/qualified/${(i <= purchaseInfo.stayActiveProgram.applicable_users_qty) ? 'customer_30_green' : 'customer_30_gray'}.png`)"
										alt="">
								</div>
							</div>
						</b-alert>
					</div>
					<div class="col-12">
						<autoship
							:show="!dashboardData.early_launch"
							:dark-theme="false"
							:next-autoship-date="dashboardData.next_autoship_date" />
					</div>
				</div>
				<is-loading
					v-else
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					class="mx-auto" />
			</div>
			<!--<div
				v-if="!loading && showRetirementCountdown"
				class="col-12 mt-2">
				<product-loyalty-widget
					:next-autoship-date="dashboardData.next_autoship_date"
					:route-user-id="$route.params.distributorId" />
			</div>-->
		</div>

		<hr class="mt-1 mb-1 mt-3">
		<div class="row col-12 btn btn-lg mb-2 text-left">
			<strong>{{ datePeriod }}</strong>
		</div>
		<div class="row">
			<div class="col">
				<distributor-period-info />
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 mb-1 h-100">
				<account-info />
			</div>
		</div>
		<div class="row mt-2 mb-2">
			<div
				class="col-12 col-md-6 mh-100 mb-2">
				<organization-notifications />
			</div>
			<div
				:class="isSmallScreen ? 'text-center' : ''"
				class="col-12 col-md-6 mh-100 mb-2">
				<div
					class="d-flex flex-column justify-content-center"
					style="height: 100%;">
					<template v-if="!loading && hasData">
						<replicated-link-selector
							class="mb-2"
							:links="dashboardData.replicated_links" />
						<link-widget
							v-if="!$user.details().hide_customers_replicated"
							class="mb-2"
							:title="translate('become_affiliate_link')"
							:description="translate('become_affiliate_link_description')"
							:link="dashboardData.other_links.become_an_affiliate" />
						<link-widget
							v-if="!$user.details().hide_customers_replicated"
							:title="translate('become_customer_link')"
							:description="translate('become_customer_link_description')"
							:link="dashboardData.other_links.become_a_customer" />
					</template>
					<template v-else>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('data_not_found')"
							:loading="loading" />
					</template>
				</div>
			</div>
		</div>
		<div class="row mb-4">
			<div class="col">
				<volume-comparition />
			</div>
		</div>
		<!-- <div class="row mb-4">
			<div class="col">
				<distributor-income-graph />
			</div>
		</div> -->
		<div class="row">
			<div class="col">
				<volume-history />
			</div>
		</div>
	</div>
</template>

<script>
import { PURCHASE_DATE, MDY_FORMAT } from '@/settings/Dates';
import { Dashboard as messages, Onboarding } from '@/translations';
import AccountInformation from '@/util/Dashboard';
import Announcement from '@/util/Announcement';
import Promotions from '@/util/Promotions';
import WindowSizes from '@/mixins/WindowSizes';
import { distributor } from '@/settings/Roles';
import { COUPONS_MIN_PERCENTAGE, COUPONS_MAX_PERCENTAGE } from '@/settings/Coupons';
import { NO_EDITABLE_STATUSES as finalStatus } from '@/settings/Statuses';
import ShowAnnouncement from '@/components/Announcement';
import Banners from '@/components/Banners';
import IntroPopup from '@/components/IntroPopup';
import VClubPopup from '@/components/VClubPopup';
import Popups from '@/components/Popups';
import MediaPopups from '@/components/Popups/MediaPopups';
import WalletAlert from '@/components/WalletAlert';
import PromotionsAlert from '@/components/PromotionsAlert/index.vue';
import NewRankAlert from '@/components/NewRankAlert';
import DistributorPeriodInfo from './DistributorPeriodInfo';
import OrganizationNotifications from './OrganizationNotifications';
import AccountInfo from './AccountInfo';
import VolumeComparition from './VolumeComparition';
import VolumeHistory from './VolumeHistory';
import ReplicatedLinkSelector from './ReplicatedLinkSelector';
import Autoship from './Autoship';
import LinkWidget from './LinkWidget';
import RetirementCountdown from '@/views/Dashboard/components/RetirementCountdown';
import { ONLY_MEMBERS_COUNTRIES } from '@/settings/Country';
import PermanentRank from '@/views/Dashboard/components/PermanentRank';
import FreeProductsPromotions from '@/views/Dashboard/components/FreeProductsPromotions';
import OrderDocumentAlert from '@/views/Dashboard/components/OrderDocumentAlert';
import FormPopups from '@/components/Popups/FormPopups';

export default {
	name: 'DistributorDashboard',
	messages: [messages, Onboarding],
	components: {
		FormPopups,
		FreeProductsPromotions,
		PermanentRank,
		RetirementCountdown,
		MediaPopups,
		WalletAlert,
		DistributorPeriodInfo,
		NewRankAlert,
		// DistributorIncomeGraph,
		AccountInfo,
		VolumeComparition,
		VolumeHistory,
		ReplicatedLinkSelector,
		LinkWidget,
		ShowAnnouncement,
		Banners,
		Autoship,
		IntroPopup,
		Popups,
		OrganizationNotifications,
		PromotionsAlert,
		OrderDocumentAlert,
		VClubPopup,
	},
	mixins: [WindowSizes],
	data() {
		return {
			rangeStart: '',
			rangeEnd: '',
			accountInformation: new AccountInformation(),
			announcement: new Announcement(),
			hideAnnouncementEndpoint: new Announcement(),
			promotionsInfo: new Promotions(),
			distributor,
			alertOrder: [
				'form', 'media', 'intro', 'other', 'ranks',
				'order_verifications', 'wallet', 'promotions', 'v_club',
			],
			finalStatus,
			ONLY_MEMBERS_COUNTRIES,
			themeName: process.env.VUE_APP_THEME,
			COUPONS_MIN_PERCENTAGE,
			COUPONS_MAX_PERCENTAGE,
		};
	},
	computed: {
		dashboardData() {
			try {
				const { response } = this.accountInformation.data.response.data;
				return response;
			} catch (error) {
				return {};
			}
		},
		hasData() {
			const response = Object.keys(this.dashboardData).length;
			return !!response;
		},
		hasNextPurchaseInfo() {
			if (this.dashboardData.status !== 'active') {
				return true;
			}
			return (this.dashboardData.next_purchase_date && this.diffDays > 0);
		},
		fullName() {
			try {
				return this.dashboardData.full_name;
			} catch (e) {
				return this.$user.details().fullname;
			}
		},
		requestUserCountry() {
			if (typeof this.dashboardData.country !== 'undefined') {
				return this.dashboardData.country;
			}
			return this.$user.details().type === this.distributor ? this.$user.details().country.iso_code_2 : '';
		},
		showRetirementCountdown() {
			return typeof this.dashboardData.retirement_date !== 'undefined';
		},
		retirementData() {
			try {
				return this.dashboardData.retirement_date;
			} catch (e) {
				return {};
			}
		},
		hasPermanentRank() {
			try {
				return typeof this.dashboardData.permanent_rank !== 'undefined' && this.dashboardData.permanent_rank !== null;
			} catch (e) {
				return false;
			}
		},
		loading() {
			return !!this.accountInformation.data.loading;
		},
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			return '';
		},
		diffDays() {
			// QA: the date format below is used only to get the date diff.
			// why not name the computed function getDateDifference
			return this.$moment(this.dashboardData.next_purchase_date.date).diff(this.$moment().format('YYYY-MM-DD'), 'days');
		},
		purchaseInfo() {
			let variant = PURCHASE_DATE.expired.class;
			let texts = [this.translate('your_account_is_inactive', { link: this.$router.resolve({ name: 'MainStore' }).href })];
			const stayActiveProgram = this.dashboardData.promotions?.['stay_active_program'] ?? null;
			if (this.finalStatus.includes(this.dashboardData.status)) {
				variant = PURCHASE_DATE.expired.class;
				texts = [this.translate('your_account_is_closed', { status: this.translate(this.dashboardData.status), date: this.$moment(this.dashboardData.termination_date.date).format(MDY_FORMAT) })];
			} else if (this.dashboardData.status === 'suspended') {
				variant = PURCHASE_DATE.expired.class;
				texts = [this.translate('your_account_is_suspended')];
			} else if (this.dashboardData.status === 'active' && this.dashboardData.next_purchase_date && (this.diffDays >= 0 || stayActiveProgram !== null)) {
				variant = PURCHASE_DATE.default.class;
				texts = [this.translate('your_account_is_active_until', { date: this.$moment(this.dashboardData.next_purchase_date.date).format(MDY_FORMAT) })];
			}
			// } else if (this.dashboardData.can_sponsor_checkout === false) {
			// 	texts.push(this.translate('sponsor_inactivity'));
			// }

			return {
				variant,
				text: texts.join('<hr class="my-2">'),
				stayActiveProgram,
			};
		},
		announcements() {
			try {
				const { data } = this.announcement.data.response.data;
				return data;
			} catch (error) {
				return {};
			}
		},
		hasAnnouncements() {
			const response = Object.keys(this.announcements).length;
			return !!response;
		},
		freeProductsPromotions() {
			try {
				const { response } = this.promotionsInfo.data.response.data;
				if (Array.isArray(response) && response.length === 0) {
					return {};
				}
				return response;
			} catch (error) {
				return {};
			}
		},
		currentAlert() {
			return this.alertOrder[0];
		},
		stayActivePromoRedirection() {
			return { name: 'SponsoredDistributors', query: { status: 'active', sortDir: 'DESC', sortCol: 'product_id' } };
		},
		isSmallScreen() {
			return ['xs', 'sm'].includes(this.windowWidth);
		},
	},
	mounted() {
		// Upon logout, this.$user is cleared, causing this view to be re-rendered as well (since this.$user has changed)
		// therefore the endpoints get requested again and they return some errors (401) because this.$user is empty now
		// this happens faster than the redirection to the Login view
		if (Object.keys(this.$user.details()).length > 0) {
			let user = this.$user.details().id;
			if (this.$route.params.distributorId) {
				user = this.$route.params.distributorId;
			}
			this.accountInformation.getAccountInfo(user);
			this.announcement.getAnnouncementsList(user);
			this.promotionsInfo.getPromotionsAlert(user);
		}
	},
	methods: {
		nextAlert() {
			this.alertOrder.shift();
		},
		hideAnnouncement(id) {
			let user = this.$user.details().id;
			if (this.$route.params.distributorId) {
				user = this.$route.params.distributorId;
			}
			this.hideAnnouncementEndpoint.hideAnnouncement(user, id).then(() => {
				this.announcement.getAnnouncementsList(user);
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
	},
};
</script>
<style scoped>
.big-countdown {
	border-radius:10px;
	width:120px;
	height: 110px;
}
.big-countdown-text {
	margin-top: -7px;
	font-size:large
}
</style>
