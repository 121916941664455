<!-- eslint-disable vue/no-v-html -->
<template>
	<custom-modal
		:title="translate(`${popupCode}_title`)"
		:sub-title="translate(`${popupCode}_pre_title`).toUpperCase()"
		ref-name="form-modal"
		modal-size="lg"
		confirm-text=""
		:show-close-button="false"
		:close-on-confirm="true"
		:show="showModal">
		<template v-if="Object.keys(currentPopup).length > 0">
			<b-row
				v-if="!confirmation"
				class="mx-3">
				<!--<b-col cols="12">-->
				<!--	<p class="text-lg-center mt-2 mb-3">-->
				<!--		{{ translate(`${popupCode}_pre_title`) }}-->
				<!--	</p>-->
				<!--</b-col>-->
				<b-col class="text-center d-flex align-items-center justify-content-center">
					<div>
						<b-form-group class="h6 text-left">
							<b-form-radio-group
								id="user_action"
								v-model="userAction"
								:options="actionsAsOptions"
								name="radio-options"
								size="lg"
								stacked />
						</b-form-group>
					</div>
					<b-card-group
						v-if="false"
						class="mt-2 mb-3"
						deck>
						<b-card
							v-for="option in actions"
							:key="option.action"
							no-body
							class="pointer"
							:class="cardClass[option.action]"
							@click="userAction=option.action">
							<b-card-body class="text-center d-flex align-items-center justify-content-center">
								<p class="mb-0 h5">
									{{ translate(`${popupCode}_action_${option.action}_title`) }}
								</p>
							</b-card-body>
							<!--<b-card-header-->
							<!--	class="text-center"-->
							<!--	:header-class="headerClass[option.action]">-->
							<!--	<p class="mb-0 h5">-->
							<!--		{{ translate(`${popupCode}_action_${option.action}_title`) }}-->
							<!--	</p>-->
							<!--</b-card-header>-->
							<!--<b-card-body>-->
							<!--	<b-card-text>-->
							<!--		{{ translate(`${popupCode}_action_${option.action}_text`) }}-->
							<!--	</b-card-text>-->
							<!--</b-card-body>-->
						</b-card>
					</b-card-group>
				</b-col>
			</b-row>
			<b-form
				v-if="confirmation && userAction === 'confirm'"
				class="mx-3"
				@change="clearError($event.target.name)">
				<h4>
					{{ translate('event_personal_information') }}
				</h4>
				<b-row>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('first_name') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-input
								id="first_name"
								v-model="form.first_name"
								:class="hasError('first_name') ? 'is-invalid' : ''"
								:placeholder="translate('first_name')"
								required />
							<template v-if="hasError('first_name')">
								<span
									v-for="error in errors['first_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('last_name') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-input
								id="last_name"
								v-model="form.last_name"
								:class="hasError('last_name') ? 'is-invalid' : ''"
								:placeholder="translate('last_name')"
								required />
							<template v-if="hasError('last_name')">
								<span
									v-for="error in errors['last_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('badge_name') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-input
								id="badge_name"
								v-model="form.badge_name"
								:class="hasError('badge_name') ? 'is-invalid' : ''"
								type="text"
								maxlenght="8"
								:placeholder="translate('badge_name')"
								required />
							<p class="text-small text-muted mb-0 pb-0">
								{{ translate('badge_name_message') }}
							</p>
							<template v-if="hasError('badge_name')">
								<span
									v-for="error in errors['badge_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('gender') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-select
								v-model="form.gender"
								:class="hasError('gender') ? 'is-invalid' : ''">
								<b-form-select-option
									disabled
									:value="null">
									{{ translate('select_option') }}
								</b-form-select-option>
								<b-form-select-option
									v-for="gender in genders"
									:key="gender"
									:value="gender">
									{{ translate(gender) }}
								</b-form-select-option>
							</b-form-select>
							<template v-if="hasError('gender')">
								<span
									v-for="error in errors['gender']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<hr>
				<h4>
					{{ translate('event_roommate_section') }}
				</h4>
				<b-row>
					<b-col
						v-if="canChooseCompanion"
						class="mb-2"
						cols="12">
						{{ translate('companion_info') }}
					</b-col>
					<b-col
						v-if="canChooseCompanion"
						cols="6">
						<b-form-group>
							<b-form-select
								id="roommate_preference"
								v-model="form.roommate_preference"
								:class="hasError('roommate_preference') ? 'is-invalid' : ''">
								<b-form-select-option
									:value="null"
									disabled>
									{{ translate('select_option') }}
								</b-form-select-option>
								<b-form-select-option
									v-for="option in companionTypes"
									:key="option"
									:value="option">
									{{ translate(`roommate_preference_${option}`) }}
								</b-form-select-option>
							</b-form-select>
							<template v-if="hasError('roommate_preference')">
								<span
									v-for="error in errors['roommate_preference']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="form.roommate_preference === 'roommate'">
					<b-col>
						<b-form-group>
							<label for="roommate_name">
								{{ translate('roommate_name') }}
								<span class="text-muted">
									<i>
										({{ translate('optional') }})
									</i>
								</span>
							</label>
							<b-form-input
								id="roommate_name"
								v-model="form.roommate_name"
								:class="hasError('roommate_name') ? 'is-invalid' : ''"
								:placeholder="translate('roommate_name')" />

							<template v-if="hasError('roommate_name')">
								<span
									v-for="error in errors['roommate_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label for="roommate_username">
								{{ translate('roommate_username') }}
								<span class="text-muted">
									<i>
										({{ translate('optional') }})
									</i>
								</span>
							</label>
							<b-form-input
								id="roommate_username"
								v-model="form.roommate_username"
								:class="hasError('roommate_username') ? 'is-invalid' : ''"
								:placeholder="translate('roommate_username')" />
							<template v-if="hasError('roommate_username')">
								<span
									v-for="error in errors['roommate_username']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col
						cols="12">
						<b-alert
							show
							variant="warning"
							class="mb-3">
							*{{ translate('no_roommate_message') }}
						</b-alert>
					</b-col>
				</b-row>
				<b-row v-if="form.roommate_preference === 'companion'">
					<b-col>
						<b-form-group>
							<label
								for="companion_first_name">
								{{ translate('companion_first_name') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-input
								id="companion_first_name"
								v-model="form.companion_first_name"
								:class="hasError('companion_first_name') ? 'is-invalid' : ''"
								:placeholder="translate('companion_first_name')" />
							<template v-if="hasError('companion_first_name')">
								<span
									v-for="error in errors['companion_first_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label
								for="companion_last_name">
								{{ translate('companion_last_name') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-input
								id="companion_last_name"
								v-model="form.companion_last_name"
								:class="hasError('companion_last_name') ? 'is-invalid' : ''"
								:placeholder="translate('companion_last_name')" />
							<template v-if="hasError('companion_last_name')">
								<span
									v-for="error in errors['companion_last_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label
								for="companion_last_name">
								{{ translate('bed_preference') }}
								<span class="text-danger">*</span>
							</label>
							<b-form-select
								id="bed_preference"
								v-model="form.bed_type"
								:class="hasError('bed_preference') ? 'is-invalid' : ''">
								<b-form-select-option
									:value="null"
									disabled>
									{{ translate('select_option') }}
								</b-form-select-option>
								<b-form-select-option
									v-for="option in bedTypes"
									:key="option"
									:value="option">
									{{ translate(`bed_preference_${option}`) }}
								</b-form-select-option>
							</b-form-select>
							<template v-if="hasError('bed_preference')">
								<span
									v-for="error in errors['bed_preference']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col
						cols="12"
						class="mb-2">
						<strong>
							{{ translate('leader_information') }}
						</strong>
					</b-col>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('leader_name') }}
								<span class="text-muted">
									<i>
										({{ translate('optional') }})
									</i>
								</span>
							</label>
							<b-form-input
								id="leader_name"
								v-model="form.leader_name"
								:placeholder="translate('leader_name')"
								:class="hasError('leader_name') ? 'is-invalid' : ''"
								required />
							<template v-if="hasError('leader_name')">
								<span
									v-for="error in errors['leader_name']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<label>
								{{ translate('leader_username') }}
								<span class="text-muted">
									<i>
										({{ translate('optional') }})
									</i>
								</span>
							</label>
							<b-form-input
								id="leader_username"
								v-model="form.leader_username"
								:placeholder="translate('leader_username')"
								:class="hasError('leader_username') ? 'is-invalid' : ''"
								required />
							<template v-if="hasError('leader_username')">
								<span
									v-for="error in errors['leader_username']"
									:key="error"
									class="invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</b-row>
				<hr>
				<h4>
					{{ translate('event_gift_section') }}
				</h4>
				<b-row>
					<b-col cols="12">
						<b-alert
							show
							variant="info"
							class="mb-3">
							<p v-html="translate(`address_option_${popupOptions.address_option}`, { address: popupOptions.pickup_address })" />
						</b-alert>
					</b-col>
					<b-col v-if="popupOptions.address_option === 'shipping'">
						<address-country-form
							v-model="form.address"
							address="shipping"
							user-country
							prefix-errors="address."
							:hide-personal-document="true"
							:form-import="form.address"
							:show-address-agreement="false"
							:display-submit-button="false"
							:display-goback-button="false"
							:allow-change-country="false"
							:errors-import="errors"
							:label="translate('address')" />
					</b-col>
				</b-row>
			</b-form>
			<template v-else-if="confirmation && actions[userAction].show_confirmation_text">
				<div
					class="mx-3">
					<h3 class="text-center">
						{{ translate(`${popupCode}_action_${userAction}_confirmation_title`) }}
					</h3>
					<p
						class="text-lg-center mt-2 mb-3"
						v-html="translate(`${popupCode}_action_${userAction}_confirmation_text`)" />
				</div>
			</template>
		</template>
		<template #footer>
			<div class="d-flex justify-content-center text-center">
				<button
					v-if="!confirmation"
					class="btn btn-primary"
					:disabled="userAction === ''"
					@click="confirmation = true">
					{{ translate('next') }}
				</button>
				<button
					v-if="confirmation"
					:disabled="loading"
					class="btn btn-primary"
					@click="saveInfo">
					{{ translate('confirm') }}
					<em
						v-if="loading"
						class="fa fa-fw fa-spinner fa-pulse" />
				</button>
			</div>
			<div
				v-if="confirmation"
				class="d-flex justify-content-center text-center mt-1">
				<div
					class="py-2">
					<span
						class="text-muted pointer"
						@click="confirmation=false">
						{{ translate('go_back') }}
					</span>
				</div>
			</div>
		</template>
	</custom-modal>
</template>
<script>
import { admin } from '@/settings/Roles';
import { Popups, PersonalInformation } from '@/translations';
import Popup from '@/util/Popup';
import CustomModal from '@/components/CustomModal';
import { GENDERS as genders } from '@/settings/Profile';
import AddressCountryForm from '@/components/AddressCountryForm';

export default {
	name: 'FormPopups',
	components: { AddressCountryForm, CustomModal },
	messages: [Popups, PersonalInformation],
	data() {
		return {
			loading: false,
			admin,
			userAction: '',
			confirmation: false,
			companionTypes: ['companion', 'roommate'],
			bedTypes: ['1_bed', '2_beds'],
			form: {
				action: null,
				first_name: null,
				last_name: null,
				badge_name: null,
				gender: null,
				bed_type: null,
				roommate_preference: null,
				roommate_name: null,
				roommate_username: null,
				companion_first_name: null,
				companion_last_name: null,
				leader_name: null,
				leader_username: null,
				address: {},
			},
			genders,
			alert: new this.$Alert(),
			currentPopup: {},
			showModal: false,
			queueIndex: null,
			queueLength: null,
			showPopupsUtil: new Popup(),
			hidePopupsUtil: new Popup(),
			dontShowThis: false,
			queue: [],
			playerOptions: {
				responsive: true,
				speed: false,
			},
		};
	},
	computed: {
		userId() {
			return this.$user.details().id;
		},
		popupCode() {
			try {
				return this.currentPopup.code_name;
			} catch (error) {
				return '';
			}
		},
		canChooseCompanion() {
			try {
				return this.popupOptions.allow_choose_companion;
			} catch (error) {
				return false;
			}
		},
		cardClass() {
			return {
				cancel: this.userAction === 'cancel' ? 'border-primary' : '',
				confirm: this.userAction === 'confirm' ? 'border-primary' : '',
				ask_later: this.userAction === 'ask_later' ? 'border-primary' : '',
			};
		},
		errors: {
			get() {
				try {
					const { errors } = this.hidePopupsUtil.data.errors;
					if (Object.keys(errors).length > 0) {
						return errors;
					}
					return {};
				} catch (error) {
					return {};
				}
			},
			set(newErrors) {
				this.hidePopupsUtil.data.errors.errors = newErrors;
			},
		},
		popupOptions() {
			try {
				return this.currentPopup.options;
			} catch (error) {
				return {};
			}
		},
		actions() {
			try {
				return this.currentPopup.options.actions;
			} catch (error) {
				return [];
			}
		},
		actionsAsOptions() {
			try {
				return Object.keys(this.actions).map((action) => ({
					value: action,
					text: this.translate(`${this.popupCode}_action_${action}_title`),
				}));
				// return Object.keys(this.actions).forEach((action) => {
				// 	this.actions[action].value = action.action;
				// 	this.actions[action].text = this.translate(`${this.popupCode}_action_${action.action}_title`);
				// });
			} catch (error) {
				console.log(error);
				return {};
			}
		},
	},
	watch: {
		queueIndex(value) {
			// If user already saw last media popup
			if (this.queueIndex >= this.queueLength) {
				this.$emit('done');
			}

			this.currentPopup = this.queue[value];
			this.showModal = true;
		},
		confirmation() {
			Object.keys(this.form).forEach((key) => {
				this.errors = [];
				this.form[key] = null;
				if (key === 'address') {
					this.form[key] = {};
				}

				if (!this.canChooseCompanion) {
					this.form.roommate_preference = 'roommate';
				}

				if (['first_name', 'last_name'].includes(key)) {
					this.form[key] = this.popupOptions[key];
				}
			});
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			if (!this.admin.includes(this.$user.details().type)) {
				this.showPopupsUtil.getFormPopups(this.userId).then((response) => {
					this.queue = response.map((popup) => ({
						id: popup.id,
						code_name: popup.attributes.code_name,
						options: popup.attributes.options,
					}));
					this.queueIndex = 0;
					this.queueLength = this.queue.length;
					this.showPopups();
				});
			} else {
				this.$emit('done');
			}
		},
		saveInfo() {
			let options = {
				action: this.userAction,
			};
			if (this.userAction === 'confirm') {
				options = this.form;
				options.action = this.userAction;
			}
			this.form.action = this.userAction;
			this.loading = true;
			this.hidePopupsUtil.saveFormPopup(this.userId, this.currentPopup.id, options).then((response) => {
				this.showModal = false;
				this.queueIndex += 1;
				this.alert.toast('success', response.response.message);
			}).finally(() => {
				this.loading = false;
			});
		},
		showPopups() {
			if (this.queueLength === 0) {
				this.$emit('done');
			}
		},
		hasError(field) {
			return typeof this.errors[field] !== 'undefined';
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
	},
};
</script>
<style>
.popup-input span {
	font-size: 0.75em;
}
</style>
