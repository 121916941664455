<template>
	<b-modal
		:ref="refName"
		hide-footer
		hide-header
		no-close-on-backdrop
		no-close-on-esc
		:size="modalSize"
		body-class="p-0"
		centered
		@hide="close()">
		<div
			class="header mb-4"
			:class="{
				'header-edge': showEdge,
			}"
			:style="{
				'--background-color': backgroundColor,
				backgroundColor,
				backgroundImage: 'url(' + backgroundImage + ')',
			}">
			<div
				v-if="showCloseButton"
				class="close-button"
				@click="close(true)">
				<span class="close-text">{{ closeText }}</span> &times;
			</div>
			<slot name="header">
				<div class="text-center text-white py-2">
					<p
						v-if="preTitle"
						class="pre-title m-0 p-0 px-2">
						{{ preTitle }}
					</p>
					<p
						v-if="title"
						class="title m-0 p-0 px-2">
						{{ title }}
					</p>
					<div
						class="sub-title">
						<h5
							v-if="subTitle">
							{{ subTitle }}
						</h5>
						<slot
							v-else
							name="sub-title" />
					</div>
				</div>
			</slot>
		</div>
		<div class="pt-3">
			<slot />
		</div>
		<div class="my-3">
			<slot
				name="footer">
				<div class="d-flex justify-content-center text-center">
					<div
						class="bg-primary py-2 px-5 pointer font-weight-bold"
						style="min-width: 25%;"
						@click="confirm()">
						<span class="text-white">
							{{ confirmText }}
						</span>
					</div>
				</div>
				<div class="d-flex justify-content-center text-center mt-1">
					<div
						class="py-2">
						<span
							class="text-muted pointer"
							@click="cancel()">
							{{ cancelText }}
						</span>
					</div>
				</div>
			</slot>
		</div>
	</b-modal>
</template>

<script>

export default {
	name: 'CustomModal',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		preTitle: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			required: true,
		},
		subTitle: {
			type: String,
			default: '',
		},
		confirmText: {
			type: String,
			default: '',
		},
		cancelText: {
			type: String,
			default: '',
		},
		modalSize: {
			type: String,
			default: 'md',
		},
		closeText: {
			type: String,
			default: '',
		},
		refName: {
			type: String,
			required: true,
		},
		closeOnConfirm: {
			type: Boolean,
			default: true,
		},
		showEdge: {
			type: Boolean,
			default: true,
		},
		backgroundColor: {
			type: String,
			default: '#df703d',
		},
		backgroundImage: {
			type: String,
			default: 'https://velovita-dev.s3-us-west-1.amazonaws.com/backoffice/assets/themes/Velovita/velovita_pattern.webp',
		},
	},
	watch: {
		show() {
			if (this.show) {
				this.open();
			} else {
				this.close();
			}
		},
	},
	methods: {
		open() {
			this.$refs[this.refName].show();
			this.$emit('update:show', true);
		},
		close(isCloseButton = false) {
			this.$refs[this.refName].hide();
			this.$emit('update:show', false);
			if (isCloseButton) {
				this.$emit('pressClose');
			}
		},
		confirm() {
			if (this.closeOnConfirm) {
				this.close();
			}
			this.$emit('confirm');
		},
		cancel() {
			this.close();
			this.$emit('cancel');
		},
	},
};
</script>
<style scoped>
.header {
	position: relative;
	background-size: cover;
	width: 100% !important;
	padding-bottom: 15px;
	padding-top: 15px;
}

.header-edge:after {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 0;
	height: 0;
	border-left: solid 20px transparent;
	border-right: solid 20px transparent;
}

.pre-title {
	font-size: 22px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: lighter;
	color: #fff;
	letter-spacing: .2rem;
}

.title {
	font-size: 36px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: bold;
	color: #fff;
}

.sub-title {
	padding-left: 10px;
	padding-right: 10px;
	font-size: 26px;
	font-family: 'Montserrat', sans-serif !important;
	font-weight: 0;
	color: #fff;
}

.close-button {
	font-size: 20px;
	position: absolute;
	color: white;
	top: 0;
	right: 10px;
	cursor: pointer;
}

.close-text {
	font-size: 15px;
}
</style>

<style>
.text-highlight {
	color:#65b2e8;
}

.text-secondary-dark {
	color: #666666;
}

.text-secondary-light {
	color: #e2e2e2;
}

/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
.modal-lg-custom-2 {
	max-width: 650px !important;
	width: 650px !important;
}
.modal-lg-custom-3 {
	max-width: 700px !important;
	width: 700px !important;
}

.header-edge:after {
	border-top: solid 20px var(--background-color);
}
</style>
