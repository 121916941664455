<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="text-muted text-uppercase font-weight-bold text-left">
				{{ translate(promoCodeName + '_title') }}
				<a
					v-if="link"
					v-b-tooltip.hover
					:href="link"
					target="_blank"
					:title="translate('view_details')"
					class="text-decoration-none text-muted link-primary">
					<i class="fas fa-info-circle" />
				</a>
			</div>
		</div>
		<b-row>
			<b-col class="mx-3 mb-3 text-left">
				<p
					class="mb-0 text-muted"
					v-html="translate(promoCodeName + '_description')" />
			</b-col>
		</b-row>
		<div>
			<div class="row justify-content-center px-3 h5 mb-3">
				<b v-html="translate('applicable_orders', { currentOrders: data.applicable_orders, promoOrders: data.min_promotion_orders, color: data.applicable_orders < data.min_promotion_orders ? '' : '#7ebc5a !important' })" />
			</div>
		</div>
		<b-row class="w-100 pl-3">
			<b-col
				class="col-6 text-truncate pointer text-muted text-center">
				<b-progress
					:max="maxProgress('referral_bonus')"
					precision="0.01"
					:animated="true"
					:variant="progressVariant('referral_bonus')"
					height="30px"
					striped
					class="mt-auto">
					<b-progress-bar
						:value="currentProgress('referral_bonus')"
						:class="progressClass('referral_bonus')">
						<strong style="width: 100%; position: absolute; font-size: 1.7em; margin-left: -10px">
							<span class="text-dark bg-light h-100 rounded px-3">
								{{ currentProgress('referral_bonus') + '% / ' + maxProgress('referral_bonus') + '%' }}
							</span>
						</strong>
					</b-progress-bar>
				</b-progress>
				+ {{ translate('referral_bonus') }}
			</b-col>
			<b-col
				class="col-6 text-truncate pointer text-muted text-center">
				<b-progress
					:max="maxProgress('leadership_matching_bonus')"
					precision="0.01"
					:animated="true"
					:variant="progressVariant('referral_bonus')"
					height="30px"
					striped
					class="mt-auto">
					<b-progress-bar
						:value="currentProgress('leadership_matching_bonus')"
						:class="progressClass('referral_bonus')">
						<strong style="width: 100%; position: absolute; font-size: 1.7em; margin-left: -10px;">
							<span class="text-dark bg-light h-100 rounded px-3">
								{{ currentProgress('leadership_matching_bonus') + '% / ' + maxProgress('leadership_matching_bonus') + '%' }}
							</span>
						</strong>
					</b-progress-bar>
				</b-progress>
				+ {{ translate('leadership_matching_bonus') }}
			</b-col>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard, Promotions as messages, Tooltip } from '@/translations';

export default {
	name: 'ProgressBar',
	messages: [Dashboard, messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			loadingSync: false,
		};
	},
	computed: {
		link() {
			try {
				return this.data.link;
			} catch (e) {
				return false;
			}
		},
		size() {
			return ['xs', 'sm'].includes(this.windowWidth) ? 'sm' : 'md';
		},
	},
	methods: {
		getFontSize(number) {
			if (number.toString().length > 3 || this.size === 'sm') {
				return '1.5rem';
			}
			return '2rem';
		},
		maxProgress(type) {
			try {
				return this.data.current_tier[type].percentage_amount;
			} catch (e) {
				return 0;
			}
		},
		currentProgress(type) {
			try {
				return this.data.achieved_tier[type].percentage_amount;
			} catch (e) {
				return 0;
			}
		},
		progressVariant(type) {
			return this.currentProgress(type) >= this.maxProgress(type) ? 'secondary' : 'primary';
		},
		progressClass(type) {
			return this.currentProgress(type) >= this.maxProgress(type) ? 'success-background' : '';
		},
	},
};
</script>
<style scoped>
.modal-lg-custom {
	max-width: 880px !important;
	width: 880px !important;
}
.modal-footer {
	justify-content: center !important;
}
.success-background {
	background-color: #7ebc5a !important;
}
.error-background {
	background-color: #AA3333 !important;
}
</style>
