<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="text-muted text-uppercase font-weight-bold text-left">
				{{ translate(promoCodeName + '_title') }}
				<a
					v-if="link"
					v-b-tooltip.hover
					:href="link"
					target="_blank"
					:title="translate('view_details')"
					class="text-decoration-none text-muted link-primary">
					<i class="fas fa-info-circle" />
				</a>
			</div>
		</div>
		<b-row>
			<b-col class="mx-3 mb-3 text-left">
				<p
					class="mb-0 text-muted"
					v-html="translate(promoCodeName + '_description')" />
			</b-col>
		</b-row>
		<b-row
			class="w-100 h-100 m-0 align-items-center justify-content-center">
			<div class="col-4">
				<img
					width="150px"
					:src="require(`@/assets/images/themes/${themeName}/promotions/tier_${data.current_tier.key}.png`)"
					alt="tier">
			</div>
			<div class="col-8">
				<div>
					<div class="">
						<div>
							<div
								class="row justify-content-center px-3 h5"
								style="margin-bottom: 0">
								<b>{{ translate('cash_bonus') }}</b>:
							</div>
						</div>
						<div
							class="row justify-content-center px-3 mb-3">
							<b><span class="text-primary h1">{{ translate('usd', { amount: data.current_tier.cash_bonus} ) }}</span></b>
						</div>
					</div>
					<div>
						<div>
							<div
								class="row justify-content-center px-3 mb-2 h5"
								style="margin-bottom: 0">
								<b>{{ translate('pgv') }}</b>:
							</div>
						</div>
						<div
							class="row justify-content-center px-3 mb-3">
							<div
								class="d-flex flex-column align-items-center flex-shrink-0"
								:class="{
									'mx-3': size === 'md',
									'mx-1': size === 'sm',
								}">
								<div
									class="circle-icon rounded-circle border-orange"
									:class="{
										'normal-circle': size === 'md',
										'small-circle': size === 'sm',
										'border-green alert-green': data.left_pgv >= data.current_tier.pgv_per_leg
									}"
									:style="{
										'font-size': getFontSize(data.left_pgv),
									}">
									{{ data.left_pgv_formatted }}
								</div>
								{{ translate('left_leg') }}
								<br>
								<span class="text-primary">{{ translate('min_pgv', { bvs: data.current_tier.pgv_per_leg_formatted }) }}</span>
							</div>
							<div
								class="d-flex flex-column align-items-center flex-shrink-0"
								:class="{
									'mx-3': size === 'md',
									'mx-1': size === 'sm',
								}">
								<div
									class="circle-icon rounded-circle border-orange"
									:class="{
										'normal-circle': size === 'md',
										'small-circle': size === 'sm',
										'border-green alert-green': data.right_pgv >= data.current_tier.pgv_per_leg
									}"
									:style="{
										'font-size': getFontSize(data.right_pgv),
									}">
									{{ data.right_pgv_formatted }}
								</div>
								{{ translate('right_leg') }}
								<br>
								<span class="text-primary">{{ translate('min_pgv', { bvs: data.current_tier.pgv_per_leg_formatted }) }}</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="row justify-content-center px-3 h5 mb-3">
						<b>{{ translate('associates') }}: <span class="text-primary">{{ `${data.sponsored_associates}/${data.current_tier.associates}` }}</span></b>
					</div>
				</div>
				<div class="row justify-content-center px-3 mb-2">
					<div
						v-for="(n, index) in data.current_tier.associates"
						:key="index">
						<img
							:src="require(`@/assets/images/themes/${themeName}/qualified/${index < data.sponsored_associates ? 'customer_30_green' : 'customer_30_gray'}.png`)"
							alt="associate"
							class="mx-1">
					</div>
				</div>
			</div>
		</b-row>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import { Dashboard, Promotions as messages, Tooltip } from '@/translations';

export default {
	name: 'CashBonus',
	messages: [Dashboard, messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			loadingSync: false,
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		link() {
			try {
				return this.data.link;
			} catch (e) {
				return false;
			}
		},
		size() {
			return ['xs', 'sm'].includes(this.windowWidth) ? 'sm' : 'md';
		},
	},
	methods: {
		getFontSize(number) {
			if (number.toString().length > 3 || this.size === 'sm') {
				return '1.5rem';
			}
			return '2rem';
		},
	},
};
</script>

<style scoped>
.text-blue {
	color: #336573 !important;
}

.border-blue {
	border: 3px solid #63C2DE;
}

.border-orange {
	border: 3px solid #e4875c;
}

.circle-icon {
	border-radius: 50%;       /* Makes it a circle */
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.normal-circle {
	width: 5.2rem;
	height: 5.2rem;
}

.small-circle {
	width: 4rem;
	height: 4rem;
}

.border-green {
	border: 3px solid #6aa50a;
}
</style>
