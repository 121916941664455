var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-100 px-3"},[_c('div',{staticClass:"text-muted text-uppercase font-weight-bold text-left"},[_vm._v(" "+_vm._s(_vm.translate(_vm.promoCodeName + '_title'))+" "),(_vm.link)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-decoration-none text-muted link-primary",attrs:{"href":_vm.link,"target":"_blank","title":_vm.translate('view_details')}},[_c('i',{staticClass:"fas fa-info-circle"})]):_vm._e()])]),_c('b-row',[_c('b-col',{staticClass:"mx-3 mb-3 text-left"},[_c('p',{staticClass:"mb-0 text-muted",domProps:{"innerHTML":_vm._s(_vm.translate(_vm.promoCodeName + '_description'))}})])],1),_c('b-row',{staticClass:"w-100 h-100 m-0 align-items-center justify-content-center"},[_c('div',{staticClass:"col-4"},[_c('img',{attrs:{"width":"150px","src":require(("@/assets/images/themes/" + _vm.themeName + "/promotions/tier_" + (_vm.data.current_tier.key) + ".png")),"alt":"tier"}})]),_c('div',{staticClass:"col-8"},[_c('div',[_c('div',{},[_c('div',[_c('div',{staticClass:"row justify-content-center px-3 h5",staticStyle:{"margin-bottom":"0"}},[_c('b',[_vm._v(_vm._s(_vm.translate('cash_bonus')))]),_vm._v(": ")])]),_c('div',{staticClass:"row justify-content-center px-3 mb-3"},[_c('b',[_c('span',{staticClass:"text-primary h1"},[_vm._v(_vm._s(_vm.translate('usd', { amount: _vm.data.current_tier.cash_bonus} )))])])])]),_c('div',[_c('div',[_c('div',{staticClass:"row justify-content-center px-3 mb-2 h5",staticStyle:{"margin-bottom":"0"}},[_c('b',[_vm._v(_vm._s(_vm.translate('pgv')))]),_vm._v(": ")])]),_c('div',{staticClass:"row justify-content-center px-3 mb-3"},[_c('div',{staticClass:"d-flex flex-column align-items-center flex-shrink-0",class:{
								'mx-3': _vm.size === 'md',
								'mx-1': _vm.size === 'sm',
							}},[_c('div',{staticClass:"circle-icon rounded-circle border-orange",class:{
									'normal-circle': _vm.size === 'md',
									'small-circle': _vm.size === 'sm',
									'border-green alert-green': _vm.data.left_pgv >= _vm.data.current_tier.pgv_per_leg
								},style:({
									'font-size': _vm.getFontSize(_vm.data.left_pgv),
								})},[_vm._v(" "+_vm._s(_vm.data.left_pgv_formatted)+" ")]),_vm._v(" "+_vm._s(_vm.translate('left_leg'))+" "),_c('br'),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.translate('min_pgv', { bvs: _vm.data.current_tier.pgv_per_leg_formatted })))])]),_c('div',{staticClass:"d-flex flex-column align-items-center flex-shrink-0",class:{
								'mx-3': _vm.size === 'md',
								'mx-1': _vm.size === 'sm',
							}},[_c('div',{staticClass:"circle-icon rounded-circle border-orange",class:{
									'normal-circle': _vm.size === 'md',
									'small-circle': _vm.size === 'sm',
									'border-green alert-green': _vm.data.right_pgv >= _vm.data.current_tier.pgv_per_leg
								},style:({
									'font-size': _vm.getFontSize(_vm.data.right_pgv),
								})},[_vm._v(" "+_vm._s(_vm.data.right_pgv_formatted)+" ")]),_vm._v(" "+_vm._s(_vm.translate('right_leg'))+" "),_c('br'),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.translate('min_pgv', { bvs: _vm.data.current_tier.pgv_per_leg_formatted })))])])])])]),_c('div',[_c('div',{staticClass:"row justify-content-center px-3 h5 mb-3"},[_c('b',[_vm._v(_vm._s(_vm.translate('associates'))+": "),_c('span',{staticClass:"text-primary"},[_vm._v(_vm._s(((_vm.data.sponsored_associates) + "/" + (_vm.data.current_tier.associates))))])])])]),_c('div',{staticClass:"row justify-content-center px-3 mb-2"},_vm._l((_vm.data.current_tier.associates),function(n,index){return _c('div',{key:index},[_c('img',{staticClass:"mx-1",attrs:{"src":require(("@/assets/images/themes/" + _vm.themeName + "/qualified/" + (index < _vm.data.sponsored_associates ? 'customer_30_green' : 'customer_30_gray') + ".png")),"alt":"associate"}})])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }